import clsx from 'clsx'
import React, { forwardRef, LegacyRef } from 'react'

export type LabelProps = {
  name: string
  label?: React.ReactNode
  useNestedLabel?: boolean
  disabled?: boolean
  hasDescription?: boolean
  className?: string
}

const Label = (
  { name, label, disabled, hasDescription, useNestedLabel, className }: LabelProps,
  ref: LegacyRef<HTMLLabelElement>,
) => {
  if (label) {
    return (
      <label
        htmlFor={name}
        className={clsx(
          'text-sm font-semibold',
          disabled ? 'text-neutral-6' : 'text-black',
          !hasDescription && 'mb-1',
          useNestedLabel && 'hidden',
          className,
        )}
        ref={ref}
      >
        {label}
      </label>
    )
  }
}

export default forwardRef(Label)
