import { Dialog } from '@headlessui/react'
import { PropsWithChildren } from 'react'

import Modal, { ModalProps } from './modal'

type BasicModalProps = ModalProps & {
  title: string | React.ReactNode
  className?: string
  buttons?: React.ReactNode
}

function BasicModal({ title, buttons, children, ...rest }: PropsWithChildren<BasicModalProps>) {
  return (
    <ModalWrapper {...rest}>
      <ModalBody title={title}>{children}</ModalBody>
      <ModalFooter>{buttons}</ModalFooter>
    </ModalWrapper>
  )
}

function ModalWrapper({
  className,
  isOpen,
  onClose,
  initialFocusRef,
  children,
}: PropsWithChildren<Omit<BasicModalProps, 'title' | 'buttons'>>) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <div
        className={`inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:align-middle ${className}`}
      >
        {children}
      </div>
    </Modal>
  )
}

const ModalFooter = ({ children }: { children: React.ReactNode }) => (
  <div className='border-neutral-3 bg-neutral-0 flex items-center justify-end gap-2 rounded-b-lg border-t p-6'>
    {children}
  </div>
)

const ModalBody = ({ children, title }: { children: React.ReactNode; title: string | React.ReactNode }) => (
  <div className='sm:flex sm:items-start'>
    <div className='mt-3 flex w-full flex-col text-center sm:mt-0 sm:text-left'>
      <div className='border-neutral-3 bg-neutral-0 w-full rounded-t-lg border-b p-6'>
        <Dialog.Title as='span' className='text-lg font-medium leading-6 text-gray-900'>
          <h2 className='m-0 text-2xl font-semibold'>{title}</h2>
        </Dialog.Title>
      </div>
      <div className='p-6'>{children}</div>
    </div>
  </div>
)

BasicModal.Wrapper = ModalWrapper
BasicModal.Body = ModalBody
BasicModal.Footer = ModalFooter

export default BasicModal
