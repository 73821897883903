import { Notyf } from 'notyf'

let notifications: (Error | string)[] = []
let notyf: Notyf | null = null

const ERROR_TIMEOUT = 10000

if (typeof window !== 'undefined') {
  notyf = new Notyf({
    duration: 5000,
    ripple: false,
    dismissible: true,
    position: {
      x: 'center',
      y: 'top',
    },
    types: [
      {
        type: 'info',
        // background: variables.warning5, TODO: is this a bug?
        icon: false,
      },
      {
        type: 'error',
        duration: ERROR_TIMEOUT,
      },
    ],
  })
}

const dedupeErrors = (error: Error | string) => {
  if (!notifications.includes(error)) {
    notyf?.error?.(error)
    notifications = notifications.concat([error])
    setTimeout(() => {
      notifications = notifications.filter((notification) => notification !== error)
    }, ERROR_TIMEOUT)
  }
}

export default {
  error: dedupeErrors,
  // @ts-ignore doesn't like the spread params
  success: (...params) => notyf?.success?.(...params),
  // @ts-ignore doesn't like the spread params
  dismiss: (...params) => notyf?.dismiss?.(...params),
  // @ts-ignore doesn't like the spread params
  dismissAll: (...params) => notyf?.dismissAll?.(...params),
}
