import clsx from 'clsx'

import { UniSideNav } from '../navigation/side-nav-uni'

interface ContentWindowProps {
  children: React.ReactNode
  sideNav?: React.ReactNode
  className?: string
}

export function ContentWindow({ children, className = '', sideNav }: ContentWindowProps) {
  const wrapperClasses = clsx(`flex flex-grow flex-col bg-white px-3 pb-3 ${className}`)
  return sideNav ? (
    <div className={`flex flex-grow bg-white pb-3 pr-3 ${className}`}>
      {sideNav}
      <main
        className={clsx(
          'max-h-[calc(100vh-90px)] overflow-y-auto', // static pixel value slightly larger than header
          'bg-uibg-1 shadow-inner-xl shadow-uibg-7 flex flex-1 flex-col rounded-2xl pt-2',
        )}
      >
        {children}
      </main>
    </div>
  ) : (
    <div className={wrapperClasses}>
      <main
        className={clsx(
          'max-h-[calc(100vh-90px)] overflow-y-auto', // static pixel value slightly larger than header
          'bg-uibg-1 shadow-inner-xl shadow-uibg-7 flex flex-1 flex-col rounded-2xl pt-2',
        )}
      >
        {children}
      </main>
    </div>
  )
}

export function WorkatoIframeContentWindow({ children }: { children: React.ReactNode }) {
  return (
    <div className={'flex flex-grow overflow-hidden bg-white pr-3'}>
      <UniSideNav />
      <main className='bg-uibg-1 flex flex-1 flex-col overflow-hidden rounded-t-2xl'>{children}</main>
    </div>
  )
}
