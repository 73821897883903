import { Dialog, DialogBackdrop } from '@headlessui/react'
import { Children, ElementRef, ElementType, MutableRefObject, PropsWithChildren, ReactNode } from 'react'

export type ModalProps = PropsWithChildren & {
  isOpen: boolean
  onClose: () => void
  initialFocusRef?: ElementRef<ElementType> | MutableRefObject<ReactNode>
}

const Modal = ({ isOpen, onClose, initialFocusRef, children }: ModalProps) => {
  if (Children.count(children) > 1) {
    throw new Error('Modal requires a single child element to work correctly!')
  }
  return (
    <Dialog
      as='div'
      className='fixed inset-0 z-[100] overflow-y-auto duration-300 ease-out data-[closed]:opacity-0'
      initialFocus={initialFocusRef as any}
      onClose={onClose}
      open={isOpen}
      transition
    >
      <div className='flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'>
        <DialogBackdrop className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className='hidden sm:inline-block sm:h-screen sm:align-middle' aria-hidden='true'>
          &#8203;
        </span>
        {children}
      </div>
    </Dialog>
  )
}

export default Modal
