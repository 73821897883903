import { atom } from 'recoil'

export type UserData = {
  email: string
  email_verified?: boolean
  sub?: string
}

export const userState = atom({
  key: 'userState',
  default: {} as UserData,
})

export const disabledQueryKeysState = atom({
  key: 'disabledQueryKeysState',
  default: {} as Record<string, any>,
})

// Get/set token keys with uni/node/view/page format so we can same
//  paginationTokens state to store tokens for any uni/node/view
export const paginationTokens = atom({
  key: 'paginationTokens',
  default: {},
})

export const selectedRoleState = atom<{ name: string | null }>({
  key: 'selectedRole',
  default: {
    // It's important this starts as null - there's useEffect logic in App.tsx that initializes this value
    name: null,
  },
})

export const showQueryDevToolsState = atom({
  key: 'showQueryDevToolsState',
  default: false,
})
