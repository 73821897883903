import { QueryClient, useQuery } from '@tanstack/react-query'
import { Role, User } from '@vendia/management-api-types'
import debug from 'debug'
import { useContext } from 'react'

import { authContext } from '../auth/auth-context'
import useApi from './use-api'

const logger = debug('app:hooks:use-current-vendia-user-query')

const sortRoles = (user?: User) =>
  user?.roles?.sort((a, b) => {
    // Always put "default" at the top
    if (a.isDefault || a.name === 'default') {
      return -1
    }
    if (b.isDefault || b.name === 'default') {
      return 1
    }
    return 0
  }) as Role[]

export default function useGetCurrentVendiaUserQuery({ ...options } = {}) {
  const { auth } = useContext(authContext)
  const api = useApi()
  const getCurrentVendiaUserQuery = useQuery({
    queryKey: ['currentVendiaUser'],
    queryFn: async () => {
      const data = await api.getUser()
      if (data.errors) {
        throw new Error(JSON.stringify(data.errors))
      }
      sortRoles(data.getUser)
      return data
    },
    enabled: Boolean(auth.authenticated && api),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  })

  return {
    getCurrentVendiaUserQuery,
  }
}

export function refetchCurrentVendiaUser({ queryClient }: { queryClient: QueryClient }) {
  return queryClient.invalidateQueries({ queryKey: ['currentVendiaUser'], refetchType: 'inactive' })
}
