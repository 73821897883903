import clsx from 'clsx'

export type LoaderProps = {
  className?: string
  padding?: boolean
}

export default function Loader({ className, padding = true, ...rest }: LoaderProps) {
  return (
    <div className={clsx('flex flex-1 flex-col items-center justify-center', padding && 'py-12')} {...rest}>
      <div className={clsx('size-12 animate-spin rounded-full border-b-2 border-purple-300', className)} />
    </div>
  )
}

export function PageLoader() {
  return (
    <div className='h-100 flex flex-1 flex-col items-center justify-center'>
      <Loader data-testid='app-loader' />
    </div>
  )
}
