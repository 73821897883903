import clsx from 'clsx'
import { hasData } from 'node_modules/cypress/types/jquery'
import { ForwardedRef, forwardRef, ReactNode } from 'react'

export type LabelNestedProps = {
  label?: ReactNode
  showNestedLabel?: boolean
  useNestedLabel?: boolean
  nestedLabelOffset?: number
  hasDescription?: boolean
}

const LabelNested = (
  { label, showNestedLabel, useNestedLabel, nestedLabelOffset, hasDescription }: LabelNestedProps,
  ref: ForwardedRef<HTMLSpanElement>,
) => {
  if (useNestedLabel && typeof label !== 'string') {
    throw new Error('Label prop must be a string with useNestedLabel')
  }

  const offsetStyle = nestedLabelOffset ? { left: `${nestedLabelOffset + 12}px` } : undefined

  if (showNestedLabel) {
    return (
      <span
        ref={ref}
        className={clsx(
          'absolute left-3 bg-transparent text-xs capitalize text-gray-500',
          hasDescription && 'top-[21px]',
        )}
        style={offsetStyle}
      >
        {label}
      </span>
    )
  }
}

export default forwardRef(LabelNested)
