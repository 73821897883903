import { FormApi, ReactFormApi, Validator } from '@tanstack/react-form'
import { FormHTMLAttributes, PropsWithChildren } from 'react'

import { submitHandler } from './utils'

export type VendiaFormApi<
  TFormData = any,
  TFormValidator extends Validator<TFormData, unknown> | undefined = undefined,
> = FormApi<TFormData, TFormValidator> & ReactFormApi<TFormData, TFormValidator>

export interface FormProps<TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined>
  extends PropsWithChildren,
    FormHTMLAttributes<HTMLFormElement> {
  form: VendiaFormApi<TFormData, TFormValidator>
}

const Form = <TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined>({
  form,
  children,
  onSubmit,
  ...rest
}: FormProps<TFormData, TFormValidator>) => {
  if (!form && !onSubmit) {
    throw new Error('Form component must have a form prop or onSubmit prop provided')
  }

  // if onSubmit is provided, use it, otherwise use the submitHandler
  const handleSubmit = onSubmit ?? submitHandler(form)

  return (
    <form onSubmit={handleSubmit} {...rest}>
      {children}
    </form>
  )
}

export default Form
