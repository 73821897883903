import clsx from 'clsx'
import React, { createContext, ReactNode, useContext } from 'react'

type CardProps = {
  // Todo Element + ReactNode not cooperating
  title?: any
  subTitle?: ReactNode
  className?: string
  childrenWrapperClassName?: string
  actions?: ReactNode
  // Add this to Cards containing a table to remove x padding around content
  isTable?: boolean
  // Add this to Cards on a white background, like in wizard steps
  onWhiteBg?: boolean
  padding?: 'none' | 'small' | 'medium' | 'large' | 'xl'
  overflow?: 'auto' | 'hidden' | 'visible' | 'scroll'
  stretch?: boolean
  expandVertical?: boolean
  contentExpandVertical?: boolean
  // Add a ribbon message to the top right of the card
  ribbonMessage?: string
  ribbonClassName?: string
  ribbonPosition?: 'top' | 'bottom' | 'corner'

  [key: string]: any
}

const CardPaddingContext = createContext({ padding: 'none' })

/**
  Refactored...
  There are only two card "sizes" now, defined by "padding" prop - will refactor name in followup PR (TODO!)
  Most are "large" and "medium" is an overall smaller Card with a smaller title, as well
  Better to keep this consistent across the UI rather than have tons of slightly different cards everywhere
  isTable is special and removes x padding so the table rows can extend to the edges of Card
*/

const Card = ({
  title,
  subTitle,
  children,
  className,
  childrenWrapperClassName,
  isTable,
  actions = null,
  padding = 'large',
  onWhiteBg = false,
  overflow = 'auto',
  stretch = false,
  expandVertical = false,
  contentExpandVertical = false,
  ribbonClassName,
  ribbonMessage,
  ribbonPosition = 'corner',
  ...rest
}: CardProps) => {
  const wrapperClasses = clsx(
    'relative overflow-hidden bg-white',
    !onWhiteBg && `shadow-uibg-7 shadow-md`,
    onWhiteBg && 'border-neutral-2 shadow-uibg-7 border shadow-sm',
    expandVertical && 'flex-1',
    contentExpandVertical && 'flex flex-col',
    className,
  )
  const paddingClasses = clsx(
    !isTable && padding === 'small' && 'p-2',
    !isTable && padding === 'medium' && 'p-4',
    !isTable && padding === 'large' && 'p-6',
    !isTable && padding === 'xl' && 'p-8',
  )
  const headerPaddingClasses = clsx(
    padding === 'small' && 'p-2',
    padding === 'medium' && 'p-4',
    padding === 'large' && 'p-6',
    padding === 'xl' && 'p-8',
  )

  const headerClasses = clsx('border-neutral-1 flex items-center justify-between border-b', headerPaddingClasses)
  const titleClasses = clsx(
    '!mb-0 font-bold !leading-none',
    (padding === 'large' || padding === 'xl') && '!text-xl',
    (padding === 'medium' || padding === 'small') && '!text-lg',
  )

  const childrenWrapperClasses = clsx(
    `overflow-${overflow} flex-1`,
    paddingClasses,
    isTable && 'pb-6',
    stretch && 'flex w-full',
    contentExpandVertical && 'flex flex-col',
    childrenWrapperClassName,
  )
  return (
    // isTable removes x padding so it's effectively 'none' for the CardRule component
    <CardPaddingContext.Provider value={{ padding: isTable ? 'none' : padding }}>
      <section className={wrapperClasses} {...rest}>
        {title && (
          <header className={headerClasses}>
            <div className='mr-12'>
              <h2 className={titleClasses}>{title}</h2>
              {subTitle && <div className='mt-2 text-balance text-xs text-black'>{subTitle}</div>}
            </div>
            <div>{actions}</div>
          </header>
        )}
        <div className={childrenWrapperClasses}>{children}</div>
        {ribbonMessage && <CardRibbon message={ribbonMessage} className={ribbonClassName} position={ribbonPosition} />}
      </section>
    </CardPaddingContext.Provider>
  )
}

interface CardRuleProps {
  className?: string
  [key: string]: any
}

// Use inside a Card with any padding size - styles will update to take full width
export const CardRule = ({ className, ...props }: CardRuleProps) => {
  const { padding } = useContext(CardPaddingContext)
  const classes = clsx(
    'border-neutral-2 border',
    padding === 'xl' && '-ml-8 -mr-8',
    padding === 'large' && '-ml-6 -mr-6',
    padding === 'medium' && '-ml-4 -mr-4',
    padding === 'small' && '-ml-2 -mr-2',
    padding === 'none' && 'w-full',
    className,
  )
  return <hr className={classes} {...props} />
}

const CardRibbon = ({
  message,
  className,
  position,
}: {
  message: string
  className?: string
  position?: 'top' | 'bottom' | 'corner'
}) => (
  <div
    className={clsx(
      'absolute z-20 bg-violet-800 py-1 text-center text-xs text-white',
      position === 'corner' && '-right-16 top-7 z-20 w-52 rotate-45 transform',
      position === 'top' && 'left-0 top-0 w-full',
      position === 'bottom' && 'bottom-0 left-0 w-full',
      className,
    )}
  >
    {message}
  </div>
)

export default Card
