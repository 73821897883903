import { User } from '@vendia/management-api-types'
import clsx from 'clsx'

import Icon from './icon'

export enum UserIconSize {
  TINY,
  SMALL,
  LARGE,
}

export interface UserIconProps {
  user: User
  isInteractive?: boolean
  ignoreTheme?: boolean
  size?: UserIconSize
}

export default function UserIcon({
  user,
  isInteractive = false,
  ignoreTheme = false,
  size = UserIconSize.LARGE,
}: UserIconProps) {
  // const theme = useVendiaTheme()
  const theme = { name: 'light' } // in case we start using the themes again one day
  const isDarkTheme = theme.name === 'dark' && !ignoreTheme
  const hasInitials = user?.firstName && user?.lastName
  const initials = `${user?.firstName?.slice(0, 1)}${user?.lastName?.slice(0, 1)}`
  const className = clsx(
    'grid place-content-center rounded-full',
    !isDarkTheme && 'bg-primaryCore',
    // The shadow here is a quick hack to make the circle show up on white when used in the dropdown menu while in wizard theme (rare use case)
    isDarkTheme && 'text-purple-1200 shadow-purple-1100 bg-white shadow',
    size === UserIconSize.LARGE && 'h-11 w-11',
    size === UserIconSize.SMALL && 'h-8 w-8',
    size === UserIconSize.TINY && 'h-5 w-5 text-[9px]',
    isInteractive && !isDarkTheme && 'hover:bg-primaryCoreLight transition-colors',
    isInteractive && isDarkTheme && 'transition-colors hover:bg-purple-50',
    !user?.email && 'animate-pulse',
  )
  const personCircleSize = size === UserIconSize.TINY ? 'xxs' : 's'
  return (
    <div className={className}>
      <div className={`${isDarkTheme ? 'text-purple-1300' : 'text-white'}`}>
        {hasInitials ? (
          <div className={`font-bold uppercase`}>{initials}</div>
        ) : (
          <Icon name='person-circle' size={personCircleSize} />
        )}
      </div>
    </div>
  )
}
