import debug from 'debug'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { userState } from 'src/utils/state'

const logger = debug('app:track-last-visited')

export const LAST_VISITED_STORAGE_KEY = 'lastVisited'

// NOTE: _was_ going to track certain URLs like last uni/node visited, but decided to just track the last path visited
//  Not restoring query params because maybe that's too specific to what they were filtering on or whatever...? TBD

const IGNORED_PATHS = ['/login', '/login/reset', '/logout', '/signup', '/callback']

export const useTrackLastVisited = () => {
  // Grab the current path from the URL
  const path = window.location.pathname
  const user = useRecoilValue(userState) as { email: string }

  useEffect(() => {
    if (path && user.email && !IGNORED_PATHS.includes(path)) {
      const lastVisited = JSON.stringify({ path })
      localStorage.setItem(`${LAST_VISITED_STORAGE_KEY}:${user.email}`, lastVisited)
    }
  }, [path])
}

// Reconstruct the last visited URL to redirect user there on login
export const getRedirectUrl = (email: string): string | null => {
  try {
    const lastVisited = localStorage.getItem(`${LAST_VISITED_STORAGE_KEY}:${email}`)
    if (lastVisited) {
      const parsed = JSON.parse(lastVisited)
      const { path } = parsed
      const redirectUrl = path
      logger('Redirecting to last visited URL', redirectUrl)
      return redirectUrl
    }
    return null
  } catch (e) {
    logger('Error parsing last visited URL', e)
    return null
  }
}
