import { ReactFormApi } from '@tanstack/react-form'
import { ForwardedRef, forwardRef } from 'react'

import Icon from '../icons/icon'

export type ErrorMessageProps = {
  name: string
  form: ReactFormApi<any>
  hasErrors: boolean
}

const ErrorMessage = ({ form, name, hasErrors }: ErrorMessageProps, ref: ForwardedRef<HTMLDivElement>) => {
  if (form) {
    return (
      <form.Subscribe
        selector={(state) => state.fieldMeta[name]}
        children={(fieldMeta) => {
          if (hasErrors) {
            return (
              <div className='align-center mt-1 flex select-none text-xs' ref={ref}>
                <Icon name='error' size='xs' className='mr-2' />
                {fieldMeta?.errors[0]}
              </div>
            )
          }
        }}
      />
    )
  }
}

export default forwardRef(ErrorMessage)
