import { ListUnis } from './hooks/use-list-unis'

export enum Tier {
  FREE = 'FREE', // TODO Remove once fully deprecated
  INDIVIDUAL_TRIAL = 'INDIVIDUAL_TRIAL',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_TRIAL = 'ENTERPRISE_TRIAL',
}

export const getTierDisplayName = (tier: Tier) => {
  const tierToDisplay = {
    FREE: 'Free trial',
    INDIVIDUAL_TRIAL: 'Free trial',
    ENTERPRISE: 'Enterprise',
    ENTERPRISE_TRIAL: 'Enterprise trial',
  }
  return tierToDisplay[tier]
}

export const TRIAL_TIERS = [Tier.FREE, Tier.INDIVIDUAL_TRIAL]

export const ENTERPRISE_TIERS = [Tier.ENTERPRISE, Tier.ENTERPRISE_TRIAL]

export const TRIAL_NODE_LIMIT = 3

export const getOwnedNodeCount = ({ unis, userId }: { unis: ListUnis; userId?: string }) => {
  let nodeCount = 0
  unis.forEach((uni) => {
    uni.nodes?.forEach((node) => {
      if (node.userId === userId) {
        nodeCount += 1
      }
    })
  })
  return nodeCount
}

export const isAtTrialNodeLimit = ({ unis, userId }: { unis: ListUnis; userId?: string }) => {
  return getOwnedNodeCount({ unis, userId }) >= TRIAL_NODE_LIMIT
}
