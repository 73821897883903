import clsx from 'clsx'
import { forwardRef } from 'react'
import { useNavigate } from 'react-router'

import Button, { ButtonProps } from '../buttons/button'
import { StepButtonsWrapper } from './step-buttons-wrapper'
import { FlowContext } from './types'

export type StepButtonProps = ButtonProps & {
  isLastStep?: boolean
  isSubmitting?: boolean
  label?: string
}

export const CancelButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, className, ...rest }: StepButtonProps, ref) => (
    <Button ref={ref} kind='tertiary' data-testid='cancel-button' {...rest} className={clsx('w-28', className)}>
      {label ?? 'Cancel'}
    </Button>
  ),
)

export const PreviousButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, className, ...rest }: StepButtonProps, ref) => (
    <Button
      {...rest}
      ref={ref}
      kind='secondary'
      data-testid='back-button'
      icon='chevron-left'
      iconSize={15}
      className={clsx('min-w-32', className)}
    >
      {label ?? 'Go Back'}
    </Button>
  ),
)

export const NextButton = forwardRef<HTMLButtonElement, StepButtonProps>(
  ({ label, className, isLastStep, isSubmitting, ...rest }: StepButtonProps, ref) => (
    <Button
      {...rest}
      ref={ref}
      kind='primary'
      data-testid='next-button'
      disabled={isSubmitting || rest.disabled}
      icon={isSubmitting ? 'refresh' : 'chevron-right'}
      iconPosition={isSubmitting ? 'left' : 'right'}
      iconProps={{
        isSpinning: isSubmitting,
        size: 15,
      }}
      className={clsx('min-w-32', className)}
    >
      {label ?? (isLastStep ? 'Finish' : 'Next')}
    </Button>
  ),
)

export type StepButtonsProps = {
  context?: FlowContext<any>
  hasCancel?: boolean
  cancelButtonLabel?: string
  onCancel?: () => void
  hasPrevious?: boolean
  previousButtonLabel?: string
  onPrevious?: () => void
  hasNext?: boolean
  nextButtonLabel?: string
  onNext?: () => void
  nextBlocked?: boolean
  isSubmitting?: boolean
  isLastStep?: boolean
  cancelButtonRef?: React.RefObject<HTMLButtonElement>
  previousButtonRef?: React.RefObject<HTMLButtonElement>
  nextButtonRef?: React.RefObject<HTMLButtonElement>
}

const StepButtons = ({
  context,
  hasCancel = false,
  hasPrevious = true,
  hasNext = true,
  onCancel,
  onPrevious,
  onNext,
  isSubmitting,
  isLastStep = false,
  nextBlocked = false,
  previousButtonRef,
  nextButtonRef,
  cancelButtonRef,
  previousButtonLabel,
  cancelButtonLabel,
  nextButtonLabel,
}: StepButtonsProps) => {
  const navigate = useNavigate()

  const handlePrevious = onPrevious ?? context?.goPrevious
  const handleNext = onNext ?? context?.goNext
  const handleCancel = onCancel ?? (() => navigate('/'))

  return (
    <StepButtonsWrapper>
      <div className='flex gap-4'>
        {hasPrevious ? (
          <PreviousButton label={previousButtonLabel} onClick={handlePrevious} ref={previousButtonRef} />
        ) : null}
        {hasCancel ? <CancelButton label={cancelButtonLabel} onClick={handleCancel} ref={cancelButtonRef} /> : null}
      </div>

      {hasNext ? (
        <NextButton
          label={nextButtonLabel}
          isSubmitting={isSubmitting}
          disabled={nextBlocked}
          onClick={handleNext}
          isLastStep={isLastStep}
          ref={nextButtonRef}
        />
      ) : null}
    </StepButtonsWrapper>
  )
}

export default StepButtons
