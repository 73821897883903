import { VendiaShareSDK } from '@vendia/share-sdk'
import debug from 'debug'
import { useContext } from 'react'
import { useRecoilValue } from 'recoil'
import config from 'src/config'

import { authContext, AuthStatus, isTokenExpired } from '../auth/auth-context'
import { refreshSession } from '../auth/cognito'
import { getEmbeddedToken, isUsingEmbeddedAuth } from '../auth/embedded'
import { selectedRoleState } from '../state'
import { useCurrentRoute } from './use-current-route'

const logger = debug('app:useApi')

export default function useApi() {
  const route = useCurrentRoute()
  const { setAuthStatus, auth } = useContext(authContext)

  // NOTE: not using useSelectedRole here to grab selected role name because it causes a circular dependency!
  //  useApi is _needed_ for useSelectedRole to work. So, here we look at recoil state first, fall
  //  back to undefined if not found which means we'll lookup user's default role on backend for getUser.
  // NOTE: right now we _always_ use default role for initial getUser call to get user's roles. Then logic
  //  in App.tsx validates roles + selected role (if set in localStorage) for all other calls to backend.
  const selectedRole = useRecoilValue(selectedRoleState)

  /* @ts-ignore - latent type error, but does not seem to affect usage */
  return new VendiaShareSDK({
    endpoint: config.shareApi,
    getCredentials: async () => {
      // Embedded auth works differently TODO: onError handling for embedded auth
      if (isUsingEmbeddedAuth()) {
        return getEmbeddedToken()
      }

      if (isTokenExpired(auth.idToken)) {
        logger('Token expired, attempting to refresh')
        const newSession = await refreshSession()
        setAuthStatus(newSession as AuthStatus)
        return newSession?.idToken
      }

      return auth.idToken
    },
    // getAsRole passes asRole variable along with to all requests from share-sdk
    // that include asRole in variables object. If object key detected and value
    // is null or undefined, it will be set to currently selected role. Eg:
    //   variables: {
    //     asRole,
    //   }
    getAsRole: () => {
      // fall back to undefined explcitly (for first getUser call only); null will error
      return selectedRole?.name ?? undefined
    },
    onError: async (error: any) => {
      // API 403 error
      if (error && error.status === 403) {
        logger('API 403 error, attempting to refresh token')
        /* Attempt token refresh */
        try {
          const newSession = await refreshSession()
          // This should trigger this hook to refresh via useCredentials
          setAuthStatus(newSession as AuthStatus)
        } catch (e) {
          console.log('Failed to refresh session', e)
          window.location.href = `${window.location.origin}/login`
        }
      }
    },
    headers: {
      'x-vendia-client': 'app',
      'x-vendia-feature': () => route ?? undefined,
      'x-vendia-role-name': () => {
        // fall back to undefined explcitly (for first getUser call only); null will error
        return selectedRole?.name ?? undefined
      },
    },
  })
}
