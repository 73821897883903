import debug from 'debug'

const logger = debug('app:embedded:auth')

export const isUsingEmbeddedAuth = () => {
  return window.location.pathname.startsWith('/embedded/')
}

export const getEmbeddedToken = () => {
  logger('---- Using embedded auth ----')

  // Try getting it from vendia_token query param
  const urlParams = new URLSearchParams(window.location.search)
  const tokenFromUrl = urlParams.get('vendia_token')
  if (tokenFromUrl) {
    return tokenFromUrl
  } else {
    console.error('vendia_token not found in URL')
  }
}

export const getEmbeddedExternalId = () => {
  // Try getting it from external_id query param
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('external_id')
}
