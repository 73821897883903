import clsx from 'clsx'
import { ReactNode } from 'react'

interface StepHeaderProps {
  title: ReactNode
  large?: boolean
  centered?: boolean
  hasMarginY?: boolean
  description?: ReactNode
  actions?: ReactNode
  className?: string
}
export const StepContentHeader = ({
  title,
  description,
  actions,
  large = false,
  centered = false,
  hasMarginY = false,
  className = '',
}: StepHeaderProps) => {
  return (
    <div
      className={clsx(
        `mb-2 flex w-full items-center`,
        hasMarginY && 'mb-8 mt-6',
        centered && 'justify-center',
        !centered && 'justify-between',
        className,
      )}
    >
      <div className={clsx('flex flex-1 flex-col justify-center', centered ? 'items-center' : 'max-w-4xl')}>
        <h2
          className={clsx(
            'font-bold',
            description ? '!mb-2' : '!mb-0',
            large ? 'text-4xl' : 'text-2xl',
            centered ? 'text-center' : 'text-left',
          )}
        >
          {title}
        </h2>
        {description && (
          <div
            className={clsx(
              'text-neutral-8 mb-2 text-balance',
              centered && 'text-center',
              large ? 'text-base' : 'text-sm',
            )}
          >
            {description}
          </div>
        )}
      </div>
      {actions}
    </div>
  )
}
