import Auth from '@aws-amplify/auth'
import { AttributeType, CognitoIdentityProviderClient, GetUserCommand } from '@aws-sdk/client-cognito-identity-provider'
import debug from 'debug'
import { decodeJwt, JWTPayload } from 'jose'
import {
  AuthorizationServer,
  calculatePKCECodeChallenge,
  Client,
  discoveryRequest,
  generateRandomCodeVerifier,
  isOAuth2Error,
  processDiscoveryResponse,
  processRefreshTokenResponse,
  refreshTokenGrantRequest,
} from 'oauth4webapi'
import safe from 'safe-await'

import config from '../../config'
import { Deferred } from '../deferred'
import { AuthStatus, clearAuthAndRedirectLogin, getStoredUserAuth, USER_AUTH_KEY } from './auth-context'

const logger = debug('app:cognito')

const noOp = (arg: any) => {}

// Resend sign up email
export async function resendVerificationEmail({
  email,
  onLoading = noOp,
  onSuccess = noOp,
  onError = noOp,
}: {
  email: string
  onLoading?: (loading: boolean) => void
  onSuccess?: (res: any) => void
  onError?: (error: any) => void
}) {
  await onLoading(true)
  if (!email) {
    throw new Error('email required for resendSignUp')
  }
  const [error, res] = await safe(
    Auth.resendSignUp(email, {
      redirect: window.location.origin,
    }),
  )

  await onLoading(false)

  if (error) {
    return onError(error)
  }
  return onSuccess(res)
}

export interface VerifiedContact {
  verified?: {
    email?: string
    phoneNumber?: string
  }
  unverified?: {
    email?: string
    phoneNumber?: string
  }
}

export function checkUser(currentUserJwtPayload: JWTPayload): VerifiedContact | undefined {
  let data = undefined
  try {
    if (currentUserJwtPayload) data = verifiedContact(currentUserJwtPayload)
  } catch (err) {
    logger('Auth.verifiedContact errors', err)
  }
  return data
}

export function checkVerification(currentUserJwt: JWTPayload): [boolean, VerifiedContact] {
  const verificationStatus = checkUser(currentUserJwt)

  if (verificationStatus && verificationStatus.unverified && verificationStatus.unverified.email) {
    logger('User email is not verfied', verificationStatus.unverified.email)
    return [false, verificationStatus]
  }

  if (!currentUserJwt.email_verified) {
    logger('redirect from loadUser')
    return [false, verificationStatus as VerifiedContact]
  }

  return [true, verificationStatus as VerifiedContact]
}

export const verifiedContact = (attrs: JWTPayload): VerifiedContact => {
  const contact: VerifiedContact = {
    verified: {},
    unverified: {},
  }

  if (attrs.email) {
    if (attrs.email_verified) {
      contact.verified!.email = attrs.email as string
    } else {
      contact.unverified!.email = attrs.email as string
    }
  }
  if (attrs.phone_number) {
    if (attrs.phone_number_verified) {
      contact.verified!.phoneNumber = attrs.phone_number as string
    } else {
      contact.unverified!.phoneNumber = attrs.phone_number as string
    }
  }

  return contact
}

export const getUserMFASettings = async (accessToken: string): Promise<string[] | undefined> => {
  if (!accessToken) {
    throw new Error("Can't get user MFA settings without an access token")
  }
  const client = new CognitoIdentityProviderClient({ region: 'us-east-1' })
  const user = await client.send(
    new GetUserCommand({
      AccessToken: accessToken,
    }),
  )

  return user.UserMFASettingList
}

export const redirectToOrgSSO = async (domain: string) => {
  const { as, client } = await discoverAuthServer()

  const currentUrl = new URL(window.location.href)
  const redirectUri = `${currentUrl.origin}/callback`
  const codeVerifier = generateRandomCodeVerifier()

  // Save the codeVerifier for later
  sessionStorage.setItem('code_verifier', codeVerifier)

  const codeChallenge = await calculatePKCECodeChallenge(codeVerifier)
  // See RFC for information about this challenge method https://www.rfc-editor.org/rfc/rfc7636#section-4.2
  const codeChallengeMethod = 'S256'

  // redirect user to as.authorization_endpoint
  const authorizationUrl = new URL(as.authorization_endpoint as string)
  authorizationUrl.searchParams.set('idp_identifier', domain)
  authorizationUrl.searchParams.set('client_id', client.client_id)
  authorizationUrl.searchParams.set('code_challenge', codeChallenge)
  authorizationUrl.searchParams.set('code_challenge_method', codeChallengeMethod)
  authorizationUrl.searchParams.set('redirect_uri', redirectUri)
  authorizationUrl.searchParams.set('response_type', 'code')
  authorizationUrl.searchParams.set('scope', 'openid profile email aws.cognito.signin.user.admin')

  window.location.href = authorizationUrl.toString()
}

let currentRefreshRequest = null as Deferred<AuthStatus> | null

/**
 * This function is smart enough to work out which type of session is being refreshed
 * and correctly use either cognito's OAuth2 refresh flow, or Amplify to refresh the
 * tokens.
 * Will return the same in-progress promise when called multiple times in quick succession.
 * @returns Promise<AuthStatus>
 */
export const refreshSession = async (): Promise<AuthStatus | undefined> => {
  if (currentRefreshRequest) {
    logger('refreshSession: already in progress')
    return currentRefreshRequest.promise
  }

  logger('refreshSession: starting')
  currentRefreshRequest = new Deferred<AuthStatus>()

  try {
    const session = getStoredUserAuth()

    if (session.type === 'SSO') {
      if (!session.refreshToken) {
        console.error('No refresh token')
        clearAuthAndRedirectLogin()
        return
      }

      // The refreshToken lives for 30 days so we can continue using it

      const refreshToken = session.refreshToken
      // Do an oauth2 refresh
      const issuer = new URL(
        `https://cognito-idp.${config.cognito.USER_POOL_REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`,
      )

      const as = await discoveryRequest(issuer).then((response) => processDiscoveryResponse(issuer, response))

      const client: Client = {
        client_id: config.cognito.USER_POOL_FEDERATED_CLIENT_ID,
        token_endpoint_auth_method: 'none',
      }

      const response = await refreshTokenGrantRequest(as, client, refreshToken)

      const result = await processRefreshTokenResponse(as, client, response)

      if (isOAuth2Error(result)) {
        console.error('error', result)
        // User needs to login from scratch
        clearAuthAndRedirectLogin()
        return
      }

      const idToken: string = result.id_token?.toString() as string
      const accessToken: string = result.access_token.toString()

      if (idToken) {
        const payload = decodeJwt(idToken)

        const [isVerified, contactDetails] = checkVerification(payload)

        const userAuth: AuthStatus = {
          userId: payload.sub as string,
          email: payload.email as string,
          type: 'SSO',
          idToken: idToken,
          refreshToken: refreshToken,
          accessToken: {
            jwtToken: accessToken,
            payload: payload as JWTPayload,
          },
          authenticated: true,
          emailVerified: isVerified,
          contactDetails: contactDetails,
        }

        window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth))

        currentRefreshRequest.resolve(userAuth)
      }
    } else if (session.type === 'COGNITO') {
      const user = await Auth.currentAuthenticatedUser()
      const amplifySession = await Auth.currentSession()
      // Update storage values with new token

      if (!amplifySession || !user) {
        window.location.href = '/logout'
      }

      const newAmplifySession: {
        idToken: { jwtToken: string; payload: JWTPayload }
        accessToken: string
        refreshToken: string
      } = await new Promise((resolve, reject) => {
        user.refreshSession(amplifySession.getRefreshToken(), (err: Error, newSession: any) => {
          if (err) {
            reject(err)
          } else {
            resolve(newSession)
          }
        })
      })

      if (newAmplifySession) {
        await Auth.currentUserCredentials()
      }

      const [isVerified, contactDetails] = checkVerification(newAmplifySession.idToken.payload)

      const userAuth: AuthStatus = {
        userId: user.username,
        email: newAmplifySession.idToken.payload.email as string,
        type: 'COGNITO',
        idToken: newAmplifySession.idToken.jwtToken,
        accessToken: newAmplifySession.idToken,
        refreshToken: newAmplifySession.refreshToken,
        authenticated: true,
        emailVerified: isVerified,
        contactDetails: contactDetails,
      }
      window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth))

      currentRefreshRequest.resolve(userAuth)
    }
  } catch (e) {
    currentRefreshRequest.reject(e)
  }

  currentRefreshRequest.promise.catch((e) => {
    logger('refreshSession: error', e)
    console.warn(e)
    window.location.href = `/login?redirect=${window.location.pathname}`
  })

  return currentRefreshRequest.promise.finally(() => {
    logger('refreshSession: finally - clearing currentRefreshRequest')
    currentRefreshRequest = null
  })
}

export const discoverAuthServer = async (): Promise<{
  as: AuthorizationServer
  client: Client
}> => {
  const issuer = new URL(
    `https://cognito-idp.${import.meta.env.NEXT_PUBLIC_USER_POOL_REGION}.amazonaws.com/${
      import.meta.env.NEXT_PUBLIC_USER_POOL_ID
    }`,
  )
  const as = await discoveryRequest(issuer).then((response) => processDiscoveryResponse(issuer, response))

  const client: Client = {
    client_id: config.cognito.USER_POOL_FEDERATED_CLIENT_ID,
    token_endpoint_auth_method: 'none',
  }

  return {
    as,
    client,
  }
}
