import { ForwardedRef, forwardRef, ReactNode } from 'react'
import Balancer from 'react-wrap-balancer'

export type LabelDescriptionProps = {
  name: string
  description?: ReactNode
}

const LabelDescription = ({ name, description }: LabelDescriptionProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div id={`${name}-description`} className='text-neutral-8 mb-1 min-h-1 text-xs' ref={ref}>
      {typeof description === 'string' ? <Balancer>{description}</Balancer> : description}
    </div>
  )
}

export default forwardRef(LabelDescription)
