import { QueryClient, useQuery } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { selectedRoleState } from '../state'
import useApi from './use-api'

const QUERY_KEY = 'listUnis'
const FIVE_MINUTES = 300000

export type ListUnis = NonNullable<ReturnType<typeof useListUnis>['listUnisQuery']['data']>

export default function useListUnis({ isAuthenticated = true } = {}) {
  const api = useApi()
  const selectedRoleName = useRecoilValue(selectedRoleState).name
  const listUnisQuery = useQuery({
    queryKey: [QUERY_KEY, selectedRoleName],
    queryFn: () => api.listUnis(),
    staleTime: FIVE_MINUTES,
    // Allow user/roles to load and logic in App.tsx to initialize selectedRole before firing
    // off first request to listUnis. Ensures we're requesting the correct unis and prevents
    // glitchy situation where app appears to finish loading, then pops back into loading state
    // (also breaks Cypress tests which think they can proceed before app is done loading)
    enabled: isAuthenticated && !!selectedRoleName,
  })

  // Fix for dynamodb sentinel value (replaces empty string in db) leaking to frontend
  if (Array.isArray(listUnisQuery.data)) {
    listUnisQuery.data.forEach((uni) => {
      if (uni.alias === '\u0000' || uni.alias === '\x00') {
        uni.alias = null
      }
    })
  }

  return {
    listUnisQuery,
  }
}

export function refetchUnis({ queryClient }: { queryClient: QueryClient }) {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEY], refetchType: 'inactive' })
}
