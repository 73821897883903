export async function enableMocking() {
  if (!import.meta.env.DEV) {
    return
  }

  // Enable mocking if the `mock` query param is present (set to 1 or true, etc.)
  const mockAPi = new URLSearchParams(window.location.search).get('mock')
  if (!mockAPi) {
    return
  }

  console.log('-----------------------------------')
  console.log('--------- MOCKING ENABLED ---------')
  console.log('-----------------------------------')

  const { worker } = await import('./mock-setup')
  return worker.start()
}
