// @ts-strict-ignore
export class Deferred<T> {
  public promise: Promise<T>
  public resolve: (value: T | PromiseLike<T>) => void
  public reject: (reason: any) => void

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = (reason: any) => reject(reason)
      this.resolve = (value: T | PromiseLike<T>) => resolve(value)
    })
  }
}
