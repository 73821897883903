import config from '../../config'
import qs from '../misc/querystring'

const SIGNED_IN_NODE_KEY = 'SignedInNode'

interface SignedInNode {
  userPoolRegion: string
  userPoolId: string
  userPoolClientId: string
  identityPoolId: string
  apiUrl: string
  uniName: string
  nodeName: string
  s3BucketArn: string
}

function getLoggedInNodeFromLocalStorage() {
  if (!config.isBrowser) {
    return
  }

  const loggedInNodeFromLocalStorageString = window.localStorage.getItem(SIGNED_IN_NODE_KEY)

  if (!loggedInNodeFromLocalStorageString) return null

  let loggedInNodeFromLocalStorage = {}

  if (loggedInNodeFromLocalStorageString) {
    try {
      loggedInNodeFromLocalStorage = JSON.parse(loggedInNodeFromLocalStorageString)
    } catch (e) {
      // console.debug('Failed parsing localStorage for loggedInNode.')
      console.error(e)
    }
  }
  return loggedInNodeFromLocalStorage as SignedInNode
}

export function getLoggedInNodeFromQueryString() {
  const loggedInNodeFromQueryString = {
    userPoolRegion: qs('upr') || qs('user-pool-region'),
    userPoolId: qs('upid') || qs('user-pool-id'),
    userPoolClientId: qs('upcid') || qs('user-pool-client-id'),
    identityPoolId: qs('ipid') || qs('identity-pool-id'),
    s3BucketArn: qs('s3'),
    apiUrl: qs('api'),
    uniName: qs('uniName'),
    nodeName: qs('nodeName'),
  }

  const nonNullEntries = Object.entries(loggedInNodeFromQueryString).filter(([, value]) => value)

  if (!nonNullEntries.length) return null

  const nonNullLoggedInNodeFromQueryString = {}

  nonNullEntries.forEach(([key, value]) => {
    // @ts-ignore not fixing now
    nonNullLoggedInNodeFromQueryString[key] = value
  })
  return nonNullLoggedInNodeFromQueryString
}

export function getLoggedInNode() {
  if (!config.isBrowser) {
    return
  }
  const loggedInNodeFromLocalStorage = getLoggedInNodeFromLocalStorage()
  const loggedInNodeFromQueryString = getLoggedInNodeFromQueryString()

  if (!loggedInNodeFromLocalStorage && !loggedInNodeFromQueryString) return null

  const loggedInNode = {
    ...loggedInNodeFromLocalStorage,
    ...loggedInNodeFromQueryString,
  }
  return loggedInNode as SignedInNode
}

function isSignedInNodeValid(signedInNode: any) {
  return (
    signedInNode &&
    signedInNode.userPoolRegion &&
    signedInNode.userPoolId &&
    signedInNode.userPoolClientId &&
    signedInNode.identityPoolId &&
    signedInNode.apiUrl &&
    signedInNode.uniName &&
    signedInNode.nodeName &&
    signedInNode.s3BucketArn
  )
}

export function getLoggedInNodeAmplifyConfig({ signedInNode = getLoggedInNode() } = {}) {
  if (!config.isBrowser) {
    return
  }
  /*
   ** 1. Get signedInNode values from local storage and query string
   ** 2. If signedInNode is valid, store it in localStorage. Otherwise, set it to null and remove it from localStorage
   ** 3. Default to values from env vars for authConfig
   */
  if (!isSignedInNodeValid(signedInNode)) {
    signedInNode = null
  }

  // Finally, default to values from env vars
  const { cognito } = config
  const authConfig = {
    mandatorySignIn: false,
    region: signedInNode?.userPoolRegion || cognito.USER_POOL_REGION,
    userPoolId: signedInNode?.userPoolId || cognito.USER_POOL_ID,
    userPoolWebClientId: signedInNode?.userPoolClientId || cognito.USER_POOL_CLIENT_ID,
    identityPoolId: signedInNode?.identityPoolId || cognito.IDENTITY_POOL_ID,
  }
  const bucketName = signedInNode?.s3BucketArn
  const region = signedInNode?.apiUrl.split('.')[2]
  const s3Config = {
    bucket: bucketName,
    region,
    level: 'public',
    customPrefix: {
      public: '_VENDIA/uploads/',
    },
  }
  return {
    Auth: authConfig,
    Storage: {
      AWSS3: s3Config,
    },
  }
}

export function getNodeSignInLink({ withOrigin = true, node = getLoggedInNodeFromLocalStorage() } = {}) {
  const origin = withOrigin ? window.location.origin : ''
  return `${origin}/login${getNodeSignInLinkQueryStringParams({ node })}`
}

export function getNodeSignInLinkQueryStringParams({ node = getLoggedInNodeFromLocalStorage() } = {}) {
  if (!node) return ''
  const { uniName, nodeName, apiUrl, s3BucketArn, identityPoolId, userPoolId, userPoolClientId } = node
  const idpParts = identityPoolId.split(':')
  const [nodeRegion] = idpParts
  return (
    `?uniName=${uniName}&nodeName=${nodeName}&upr=${nodeRegion}` +
    `&upid=${userPoolId}&upcid=${userPoolClientId}&ipid=${identityPoolId}&api=${apiUrl}&s3=${s3BucketArn}`
  )
}
